import React, { useCallback } from 'react';
import { SimpleInfoBox } from '@carcare/components';
import { ComponentState } from '@carcare/components/dist/styles';
import { useDispatch } from '../../../../store/useStore';
import DamageList from '../../../../components/damage-list/DamageList';
import fetchDamageAppraisers from '../../../../http/carcareApi/fetchDamageAppraisers';
import DamageValuationState from '../../../../utils/enums/DamageValuationState';
import DamageAppraiserValuation from '../../../../types/DamageAppraiserValuation';
import useFetchDamageValuations from '../../../../http/carcareApi/utils/useFetchDamageValuations';
import PageLink from '../../../../components/page-link/PageLink';
import DamageNoResults from '../../../../components/damage-no-results/DamageNoResults';
import { AnyAction } from 'redux';

interface RequestShortlistProps {
    readonly listTitle: string;
    readonly damageAppraiserValuations: DamageAppraiserValuation[];
    readonly isLoading: boolean;
    readonly hasFinished: boolean;
    readonly hasError: boolean;
    readonly setIsLoading: (value: boolean) => AnyAction;
    readonly setHasError: (value: boolean) => AnyAction;
    readonly setHasFinished: (value: boolean) => AnyAction;
    readonly setDamageAppraiserValuations: (
        value: DamageAppraiserValuation[]
    ) => AnyAction;
    readonly state: DamageValuationState;
    readonly limit?: string;
    readonly listPagePath: string;
    readonly listPageText: string;
    readonly noResultsTextKey?: string;
}

const RequestShortlist = ({
    listTitle,
    damageAppraiserValuations,
    isLoading,
    hasFinished,
    hasError,
    setIsLoading,
    setHasError,
    setHasFinished,
    setDamageAppraiserValuations,
    state,
    limit = '30',
    listPagePath,
    listPageText,
    noResultsTextKey,
}: RequestShortlistProps) => {
    const dispatch = useDispatch();

    const loadDamageAppraisers = useCallback(() => {
        fetchDamageAppraisers({
            dispatch,
            setIsLoading,
            setHasError,
            setHasFinished,
            setDamageAppraiserValuations,
            state,
            limit,
            offset: damageAppraiserValuations.length,
        });
    }, [
        dispatch,
        setIsLoading,
        setHasError,
        setHasFinished,
        setDamageAppraiserValuations,
        state,
        limit,
        damageAppraiserValuations,
    ]);

    useFetchDamageValuations(
        damageAppraiserValuations,
        isLoading,
        hasFinished,
        hasError,
        loadDamageAppraisers
    );

    const filteredValuations =
        damageAppraiserValuations.length > 5
            ? damageAppraiserValuations.filter(
                  (item) => damageAppraiserValuations.indexOf(item) < 5
              )
            : damageAppraiserValuations;

    return (
        <div className="ba-request-shortlist">
            <DamageList
                damageAppraiserValuations={filteredValuations}
                listTitle={listTitle}
            />
            {hasError && (
                <SimpleInfoBox
                    withBorderRadius
                    className="ba-request-shortlist__error"
                    state={ComponentState.ERROR}
                    text={{ textKey: 'somethingWentWrong' }}
                />
            )}
            {hasFinished && damageAppraiserValuations.length === 0 && (
                <DamageNoResults
                    damageAppraiserValuations={damageAppraiserValuations}
                    noResultsTextKey={noResultsTextKey}
                />
            )}
            {(hasFinished && damageAppraiserValuations.length === 0) ||
            hasError ? (
                ''
            ) : (
                <PageLink
                    path={listPagePath}
                    textKey={listPageText}
                    className="ba-request-shortlist__see-all"
                />
            )}
        </div>
    );
};

export default RequestShortlist;
