import { HeaderText } from '@carcare/components';
import { PATH_MESSAGES } from '../../router/routes';
import { useHistory } from 'react-router';
import { Thread } from 'src/http/message-center/types/generated/MessageCenterTypes';
import { FilterThreads, useFilterThreads } from './FilterThreads';
import { useTranslation } from 'react-i18next';
import useStore from 'src/store/useStore';
import { MessageTable } from './MessageTable';
import './messages.css';
import { useState } from 'react';

interface ListProps {
    readonly threads: Thread[];
}

// Default G-group-id for access (Harald A Møller). // TODO: Can be removed after dealerId decides access
const hamProdId = 'G006';
const hamNonProdId = 'G050';
// DealerIds
const MBOVAudi = '1011';
const MBOVNonAudi = '1010';

// TODO: Chat is only open for MBOV now. Should be changed when chat is scaled
const dealerIdsChatIsOpenFor = [MBOVAudi, MBOVNonAudi];

// hamId and dealerId are set as participants when sending message from bilhold-frontend
const defaultParticipants = [
    hamProdId,
    hamNonProdId,
    ...dealerIdsChatIsOpenFor,
];

const getSupportOrAdmins = (thread: Thread) => {
    const participants = thread.participants || [];

    return participants?.filter(
        (p) => p.internal && !defaultParticipants.includes(p.participantId)
    );
};

const getDealerName = (thread: Thread) => {
    const participants = thread.participants || [];
    const dealerParticipant = participants.find(
        (p) => p.internal && dealerIdsChatIsOpenFor.includes(p.participantId)
    );

    return dealerParticipant?.participantId || '';
};

const hasSomeoneResponsible = (thread: Thread) => {
    const internals = getSupportOrAdmins(thread);

    return internals.length > 0;
};

const isSeenByInternal = (thread: Thread) =>
    !!thread.participants?.some((x) => x.internal && x.seen);

const isCurrentUserResponsible = (t: Thread, username: string) =>
    t.participants?.some((p) => p.participantId === username);

const toLocalDate = (date: string) =>
    new Date(date).toLocaleDateString('nb-NO', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

const getAdminsOrSupportAsString = (thread: Thread) => {
    const supportOrAdmins = getSupportOrAdmins(thread);
    const names = supportOrAdmins.map((p) => p.name);

    const adminsToString = names.join(', ');
    return adminsToString;
};

const sortThreadsToLastUpdatedIsFirst = (
    { updatedAt: a }: Thread,
    { updatedAt: b }: Thread
) => {
    if (!a || !b) {
        return 0;
    }
    if (new Date(a) > new Date(b)) {
        return -1;
    }
    return 1;
};

function isMoreThanThreeWeeksAgo(dateString: string) {
    const inputDate = new Date(dateString);

    const currentDate = new Date();

    const threeWeeksAgo = new Date();
    threeWeeksAgo.setDate(currentDate.getDate() - 21);

    return inputDate > threeWeeksAgo;
}

export const Threadslist = ({ threads }: ListProps) => {
    const [store] = useStore();
    const [translate] = useTranslation();
    const history = useHistory();

    const {
        account: { user: loggedInUser },
    } = store;

    const {
        filter,
        setFilter,
        threads: filteredThreads,
    } = useFilterThreads(threads);

    const getSeenLabel = (seen: boolean) => {
        if (seen) {
            return translate('messageFilterAnswered');
        }

        return translate('messageFilterUnanswered');
    };

    const mapFilteredThreads = (thread: Thread) => ({
        withBackground: true,
        onClick: () => history.push(`${PATH_MESSAGES}/${thread.threadId}`),
        items: [
            {
                textContent: thread.title,
                isBold: !isSeenByInternal(thread),
            },
            {
                textContent: thread.participants?.find(
                    (x) => x.internal === false
                )?.name,
                isBold: !isSeenByInternal(thread),
            },
            {
                textContent: thread.subtitle,
                isBold: !isSeenByInternal(thread),
            },
            {
                textContent: toLocalDate(thread.updatedAt ?? ''),
                isBold: !isSeenByInternal(thread),
            },
            {
                textContent: getSeenLabel(isSeenByInternal(thread)),
                isBold: !isSeenByInternal(thread),
            },
            {
                textContent: getAdminsOrSupportAsString(thread),
            },
            {
                textContent: getDealerName(thread),
            },
        ],
    });

    const messagesImResponsible = filteredThreads
        .filter((t) => t.category === 'CHAT')
        .filter((t) => isCurrentUserResponsible(t, loggedInUser.username))
        .sort(sortThreadsToLastUpdatedIsFirst)
        .map(mapFilteredThreads);

    const [hasDateFilter, setHasDateFilter] = useState(true);

    const messagesWithoutResponsible = filteredThreads
        .filter((t) => t.category === 'CHAT')
        .filter((t) => !hasSomeoneResponsible(t))
        .filter((t) =>
            t.updatedAt && hasDateFilter
                ? isMoreThanThreeWeeksAgo(t.updatedAt)
                : true
        )
        .sort(sortThreadsToLastUpdatedIsFirst)
        .map(mapFilteredThreads);

    const restMessages = filteredThreads
        .filter((x) => x.category === 'CHAT')
        .filter(
            (x) =>
                hasSomeoneResponsible(x) &&
                !isCurrentUserResponsible(x, loggedInUser.username)
        )
        .sort(sortThreadsToLastUpdatedIsFirst)
        .map(mapFilteredThreads);

    return (
        <div className="ba-damage-list">
            <div className="ba-damage-list__row">
                <HeaderText
                    className="ba-damage-list__header"
                    textKey={translate('chatTitle')}
                />
                <FilterThreads value={filter} setValue={setFilter} />
            </div>
            <hr />

            <div className="message-table-container">
                <MessageTable
                    listItems={messagesImResponsible}
                    title={translate('messageMyCases')}
                />

                <div>
                    <MessageTable
                        listItems={messagesWithoutResponsible}
                        title={translate('newRequests')}
                    />
                    <button
                        className="button-show-older-cases"
                        onClick={() => setHasDateFilter((prev) => !prev)}
                    >
                        {hasDateFilter
                            ? 'Vis eldre saker'
                            : 'Skjul eldre saker'}
                    </button>
                </div>

                <MessageTable
                    listItems={restMessages}
                    title={translate('otherCases')}
                />
            </div>
        </div>
    );
};
