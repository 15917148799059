import { useState } from 'react';
import { Page, Spinner } from '@carcare/components';
import {
    Message,
    Thread,
} from '../../http/message-center/types/generated/MessageCenterTypes';
import PageLink from '../../components/page-link/PageLink';
import { History } from 'history';
import { PATH_MESSAGES } from '../../router/routes';
import Messages from '../request/components/messages/Messages';
import RequestError from '../request/components/request-error/RequestError';
import NewMessage from './NewMessage';
import postMessageSimple from 'src/http/message-center/postMessageSimple';
import useStore from 'src/store/useStore';
import fetchMessages from 'src/http/message-center/fetchMessages';
import InfoItem from '../request/components/info-item/InfoItem';
import { postUserNotificationForMessage } from 'src/http/carcareApi/postUserNotificationForMessage';

interface MessagePageProps {
    readonly thread?: Thread;
    readonly messages: Message[];
    readonly history: History;
    readonly hasError: boolean;
    readonly messagesHasError: boolean;
    readonly threadHasError: boolean;
    readonly dataIsLoading: boolean;
    readonly newMessageError: string;
    readonly loggedInUser: { name: string; username: string };
    readonly createMessageIsLoading: boolean;
}

const MessagePage = ({
    thread,
    messages,
    hasError,
    loggedInUser,
    messagesHasError,
    dataIsLoading,
    threadHasError,
    createMessageIsLoading,
}: MessagePageProps) => {
    const [, dispatch] = useStore();
    const [message, setMessage] = useState('');

    const customerEntryUserId = thread?.participants?.find(
        (x) => x.internal === false
    )?.participantId;

    const onSentMessage = () => {
        if (thread?.threadId) {
            fetchMessages(thread.threadId, 'mbh@moller.no', dispatch);

            if (customerEntryUserId) {
                postUserNotificationForMessage(
                    customerEntryUserId,
                    thread.threadId,
                    message
                );
            }
        }
        setMessage('');
    };

    const sendMessage = () => {
        postMessageSimple(
            dispatch,
            thread?.threadId,
            loggedInUser,
            message,
            onSentMessage
        );
    };

    if (dataIsLoading) {
        return (
            <div className="ba-list-page__spinner">
                <Spinner delayMillis={200} />
            </div>
        );
    }
    if (threadHasError || messagesHasError || hasError) {
        return <RequestError errorMessage="requestPageError" />;
    }

    const participant = thread?.participants?.find((x) => !x.internal)?.name;

    return (
        <Page className="ba-request">
            <PageLink
                className="ba-list-page__back"
                path={PATH_MESSAGES}
                textKey="back"
                isBack
            />
            <ChatInfo name={participant ?? ''} regno={thread?.title ?? ''} />
            <Messages thread={thread} messages={messages} />
            <NewMessage
                message={message}
                setMessage={setMessage}
                loggedInUser={loggedInUser}
                sendMessage={sendMessage}
                isLoading={createMessageIsLoading}
            />
        </Page>
    );
};

function ChatInfo({ name, regno }: { name: string; regno: string }) {
    return (
        <div className="ba-request-info">
            <div className="ba-request-info__wrapper">
                <InfoItem label="customer" value={name} withBorder />
                <InfoItem label="car" value={regno} withBorder withCopyClick />
            </div>
        </div>
    );
}

export default MessagePage;
