type EnvironmentVariables = {
    ADOBE_ANALYTICS_API: string;
    CARCARE_URL: string;
    CARCARE_SURVEYOR_API: string;
    CUSTOMER_API: string;
    CARCARE_PUBLIC_API: string;
    MESSAGE_CENTER: string;
    BILHOLD_API: string;
};

type Variables = { [key: string]: EnvironmentVariables };

const variables: Variables = {
    dev: {
        ADOBE_ANALYTICS_API:
            'https://assets.adobedtm.com/9ec761efc3e5/8ed804a5ce8d/launch-19d7a1810e6f-development.min.js',
        CARCARE_URL: 'https://dev.mittbilhold.no',
        CARCARE_SURVEYOR_API:
            'https://api-dev.mittbilhold.no/bilhold/surveyor/api',
        CUSTOMER_API: 'http://apidev.moller.local/CustomerApiEndpoint/api',
        CARCARE_PUBLIC_API: 'https://api-dev.mittbilhold.no/bilhold/public/api',
        MESSAGE_CENTER: 'https://message-center.dev.mollercloud.no',
        BILHOLD_API: 'https://bilhold-api.dev.mollercloud.no',
    },
    test: {
        ADOBE_ANALYTICS_API:
            'https://assets.adobedtm.com/9ec761efc3e5/8ed804a5ce8d/launch-19d7a1810e6f-development.min.js',
        CARCARE_URL: 'https://test.mittbilhold.no',
        CARCARE_SURVEYOR_API:
            'https://api-test.mittbilhold.no/bilhold/surveyor/api',
        CUSTOMER_API: 'http://apitest.moller.local/CustomerApiEndpoint/api',
        CARCARE_PUBLIC_API:
            'https://api-test.mittbilhold.no/bilhold/public/api',
        MESSAGE_CENTER: 'https://message-center.test.mollercloud.no',
        BILHOLD_API: 'https://bilhold-api.test.mollercloud.no',
    },
    stage: {
        ADOBE_ANALYTICS_API:
            'https://assets.adobedtm.com/9ec761efc3e5/8ed804a5ce8d/launch-37371f45c473-staging.min.js',
        CARCARE_URL: 'https://staging.mittbilhold.no',
        CARCARE_SURVEYOR_API:
            'https://api-staging.mittbilhold.no/bilhold/surveyor/api',
        CUSTOMER_API: 'http://apistage.moller.local/CustomerApiEndpoint/api',
        CARCARE_PUBLIC_API:
            'https://api-staging.mittbilhold.no/bilhold/public/api',
        MESSAGE_CENTER: 'https://message-center.stage.mollercloud.no',
        BILHOLD_API: 'https://bilhold-api.stage.mollercloud.no',
    },
    prod: {
        ADOBE_ANALYTICS_API:
            'https://assets.adobedtm.com/9ec761efc3e5/8ed804a5ce8d/launch-527e238d310e.min.js',
        CARCARE_URL: 'https://www.mittbilhold.no',
        CARCARE_SURVEYOR_API: 'https://api.mittbilhold.no/bilhold/surveyor/api',
        CUSTOMER_API: 'http://api.moller.local/CustomerApiEndpoint/api',
        CARCARE_PUBLIC_API: 'https://api.mittbilhold.no/bilhold/public/api',
        MESSAGE_CENTER: 'https://message-center.prod.mollercloud.no',
        BILHOLD_API: 'https://bilhold-api.prod.mollercloud.no',
    },
};

export function getCurrentEnv() {
    const origin = window.location.origin;
    if (origin.includes('dev')) {
        return 'dev';
    }
    if (origin.includes('stage')) {
        return 'stage';
    }
    if (origin.includes('bilholdadmin.moller.no')) {
        return 'prod';
    }
    return 'test';
}

export const getEnvVariable = (key: keyof EnvironmentVariables) => {
    const env = getCurrentEnv();
    return variables[env][key];
};
