import moment from 'moment';

export const remainingHours = (endTime: moment.MomentInput) =>
    moment.duration(moment(endTime).diff(moment())).asHours().toFixed(0);

export const hoursSince = (startTime: moment.MomentInput) => {
    const hours = moment
        .duration(moment().diff(moment(startTime)))
        .asHours()
        .toFixed(0);
    return Number(hours) < 25 ? hours : '';
};

export const daysSince = (startTime: moment.MomentInput) => {
    const days = moment
        .duration(moment().diff(moment(startTime)))
        .asDays()
        .toFixed(0);
    return Number(days) >= 1 ? days : '';
};

export const isExpired = (date: moment.MomentInput) =>
    moment(date).isBefore(moment());

export const sortByTime = (a: string | undefined, b: string | undefined) => {
    const timeA = moment(a ?? null);
    const timeB = moment(b ?? null);
    if (timeA > timeB) {
        return 1;
    } else if (timeA < timeB) {
        return -1;
    }
    return 0;
};
