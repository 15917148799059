import React from 'react';
import moment from 'moment';
import List from '../list/List';
import { HeaderText } from '@carcare/components';
import DamageAppraiserValuation from '../../types/DamageAppraiserValuation';
import DamageValuationStatus from '../../utils/enums/DamageValuationStatus';
import { DATE_TIME_FORMAT } from '../../utils/time/timeFormat';
import { isLastMessageFromCustomer } from './utils/logUtils';
import requestStatusText from './utils/requestStatusText';
import { PATH_REQUEST } from '../../router/routes';
import { useHistory } from 'react-router';
import licensePlateFallback from '../../utils/licensePlateFallback';

interface ListProps {
    readonly damageAppraiserValuations: DamageAppraiserValuation[];
    readonly listTitle: string;
}

const DamageList = ({ damageAppraiserValuations, listTitle }: ListProps) => {
    const history = useHistory();

    const listHeader = [
        'licencePlate',
        'customer',
        'settlement',
        'received',
        'damageCenter',
        'status',
        'surveyor',
    ];

    const valuationActionState = (appraiser: DamageAppraiserValuation) =>
        appraiser?.status === DamageValuationStatus.NEW ||
        appraiser?.status === DamageValuationStatus.RECEIVED_CHANGES ||
        (appraiser?.status === DamageValuationStatus.PROCESSING &&
            isLastMessageFromCustomer(appraiser)) ||
        (appraiser?.status === DamageValuationStatus.VALUATION_SENT &&
            isLastMessageFromCustomer(appraiser));

    const mapValuation = (valuation: DamageAppraiserValuation) => ({
        withBackground: valuationActionState(valuation),
        onClick: () => history.push(`${PATH_REQUEST}/${valuation.hash}`),
        items: [
            { textContent: licensePlateFallback(valuation) },
            { textContent: valuation?.customer?.fullName },
            {
                textKey: valuation?.damageAppraiser?.customerPaying
                    ? 'customer'
                    : 'insurance',
            },
            {
                textContent: moment(valuation?.damageAppraiser?.sentAt).format(
                    DATE_TIME_FORMAT
                ),
            },
            { textContent: valuation?.dealer?.dealerName },
            {
                textKey: requestStatusText(valuation)?.textKey,
                hours: requestStatusText(valuation)?.hours,
                days: requestStatusText(valuation)?.days,
                isBold: requestStatusText(valuation)?.isBold,
            },
            {
                textContent: valuation?.surveyor,
            },
        ],
    });

    const listItems = damageAppraiserValuations?.map((valuation) =>
        mapValuation(valuation)
    );

    return (
        <div className="ba-damage-list">
            <HeaderText
                className="ba-damage-list__header"
                textKey={listTitle}
            />
            <List listItems={listItems} listHeader={listHeader} />
        </div>
    );
};

export default DamageList;
