import moment from 'moment';
import DamageAppraiserValuation from '../../../types/DamageAppraiserValuation';
import DamageValuationStatus from '../../../utils/enums/DamageValuationStatus';

export const sentByCustomerAnswerWithin = (
    appraiser: DamageAppraiserValuation
) => {
    return moment(appraiser.answerDeadlineAt).add(24, 'hours');
};

export const isLastMessageFromDealer = (
    appraiser: DamageAppraiserValuation
) => {
    return appraiser.status === DamageValuationStatus.PROCESSING;
};

export const isLastMessageFromCustomer = (
    appraiser: DamageAppraiserValuation
) => {
    return appraiser.status === DamageValuationStatus.RECEIVED_CHANGES;
};

export const sentByDealerTimestamp = (appraiser: DamageAppraiserValuation) =>
    appraiser.updatedAt;
