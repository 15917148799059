import { getEnvVariable } from '../env';

export const CARCARE_SURVEYOR_API = getEnvVariable('CARCARE_SURVEYOR_API');
export const CARCARE_PUBLIC_API = getEnvVariable('CARCARE_PUBLIC_API');
export const CUSTOMER_API = getEnvVariable('CUSTOMER_API');
export const MESSAGE_CENTER = getEnvVariable('MESSAGE_CENTER');
export const BILHOLD_API = getEnvVariable('BILHOLD_API');

/* Damage appraiser admin */
export const GET_DAMAGE_APPRAISERS = `${CARCARE_SURVEYOR_API}/damageAppraiserValuations?state={0}&licensePlate={1}&limit={2}&offset={3}`;
export const GET_DAMAGE_CENTERS = `${CARCARE_SURVEYOR_API}/damageCenters`;
export const GET_DAMAGE_STATISTICS = `${CARCARE_SURVEYOR_API}/damageAppraiserValuations/stats/?dealerNumber={0}&fromDate={1}&toDate={2}&damageType=SMALL&damageType=WINDOW&damageType=FRAME`;
export const GET_DAMAGE_VALUATION = `${CARCARE_SURVEYOR_API}/damageAppraiserValuations/hash/{0}`;
export const UPDATE_DAMAGE_VALUATION = `${CARCARE_SURVEYOR_API}/damageAppraiserValuations/id/{0}`;
export const GET_VALUATION_IMAGES_ZIP = `${CARCARE_SURVEYOR_API}/damageAppraiserValuations/id/{0}/images/zip`;
export const GET_VALUATION_MESSAGE_TEMPLATES = `${CARCARE_SURVEYOR_API}/damageAppraiserValuations/id/{0}/templates`;
export const POST_VALUATION_SURVEYOR = `${CARCARE_SURVEYOR_API}/damageAppraiserValuations/id/{0}/surveyor`;
export const POST_VALUATION_ATTACHMENT = `${CARCARE_SURVEYOR_API}/damageAppraiserValuations/id/{0}/messages/attachment`;
export const POST_VALUATION_PRICE = `${CARCARE_SURVEYOR_API}/damageAppraiserValuations/id/{0}/quote`;
export const UPDATE_DAV_STATUS = `${CARCARE_SURVEYOR_API}/dav-status/id/{0}`;
export const NOTIFY_USER = `${CARCARE_SURVEYOR_API}/notify-user/{0}`;
export const GET_THREAD = `${MESSAGE_CENTER}/threads/category/{0}/externalId/{1}`;
export const GET_MESSAGES = `${MESSAGE_CENTER}/threads/{0}/messages`;
export const POST_MESSAGE = `${MESSAGE_CENTER}/threads/{0}`;
export const GET_THREADS_FOR_GROUPS = `${MESSAGE_CENTER}/threads/v2/{0}`;

/* Azure files */
export const DOWNLOAD_VALUATION_ATTACHMENT = `${CARCARE_PUBLIC_API}/azureFile/fileName/{0}`;
export const ATTACHMENT_FILEPATH = '/bilhold/public/api/azureFile/fileName/{0}';

/* Bilhold API */
export const NOTIFICATION_SEND = `${BILHOLD_API}/v1/notification/send`;
